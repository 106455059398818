<template>
  <div>
    <div v-if="currentVariant?.price === null">
      {{ $t('without_price') }}
    </div>
    <div
      v-if="discount && currentVariant.originalPrice > currentVariant.price"
      class="space-x-1 text-[12px]"
    >
      <span
        class="line-through"
        :class="
          currentVariant.inStock ? 'decoration-primary' : 'decoration-gray-mud'
        "
      >
        {{ getPrice(currentVariant.originalPrice / 100) }}
      </span>
      <span
        class="px-1.5"
        :class="currentVariant.inStock ? 'bg-red-hot/20' : 'bg-black/20'"
      >
        -{{ discount }}%
      </span>
    </div>
    <div
      class="font-bold text-[21px] lg:text-[29px] leading-[36px]"
      :class="currentVariant.inStock ? 'text-primary' : 'text-gray-mud'"
    >
      {{ getPrice(currentVariant.price / 100) }}
      <AppInfoToolTip
        v-if="
          (currentVariant.isOrdered || !currentVariant.inStock) &&
            getPrice(currentVariant.price / 100) != 0
        "
      ></AppInfoToolTip>
    </div>
    <div v-if="unitValue" class="flex justify-center text-sm">
      {{ unitValue }}
    </div>
  </div>
</template>
<script setup>
const currentVariant = inject('currentVariant')
const getPrice = inject('getPrice')

const discount = computed(() => useDiscountCalculator(currentVariant.value))

const unitValue = computed(() => {
  return (
    currentVariant.value?.attributes?.find(
      ({ code }) => code === 'edinitsy-izmereniia'
    )?.value || false
  )
})
</script>
