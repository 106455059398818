<template>
  <div
    class="h-[60px] flex justify-center items-center cursor-pointer relative"
    :class="[
      props.selected && 'border-primary',
      !props.variant?.inStock && 'opacity-40 crossed'
    ]"
    @click="selectVariant"
  >
    <nuxt-img
      v-if="props.variant?.image"
      :src="props.variant?.image"
      height="60"
      width="60"
      :alt="'image-' + props.variant?.slug"
      class="object-contain max-h-[50px] max-w-[60px]"
    />
    <nuxt-img
      v-else
      :src="NO_IMAGE_PATH"
      height="20"
      width="60"
      :alt="'no-image-' + props.variant?.slug"
      class="object-contain"
    />
  </div>
</template>

<script setup>
const props = defineProps({
  variant: {
    type: Object,
    default: () => {}
  },
  selected: {
    type: Boolean,
    default: false
  }
})

const selectVariant = () => {
  return (
    props.variant &&
    useRouter().replace({ params: { slug: props.variant?.slug } })
  )
}
</script>

<style scoped>
.crossed {
  position: relative;
}
.crossed::before {
  position: absolute;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='M6 18L18 6M6 6l12 12'/%3E%3C/svg%3E");
  width: 65px;
  height: 65px;
}
</style>
