<template>
  <Disclosure
    v-slot="{ open }"
    :default-open="true"
    as="div"
    class="border-b-[1px] border-gray pb-6 mt-2 lg:mt-6"
  >
    <div class="w-full py-2 flex text-[15px] font-bold">
      <span v-if="!variant?.deliveryTime">{{ $t('Доставка') }}</span>
      <span v-else>{{ $t('delivery_time') }} {{ variant.deliveryTime }}
        {{ $t('дня') }}</span>
      <DisclosureButton as="template" class="">
        <IconsDropdownChevron
          :active="open"
          active-class="!text-gray-mud"
          class="ml-auto"
        />
      </DisclosureButton>
    </div>

    <DisclosurePanel>
      <ul class="space-y-4 mt-3">
        <li
          v-for="shipping in product?.shippingMethods"
          :key="shipping?.id"
          class="shipping-item"
        >
          <!--          <IconsHome class="shipping-item&#45;&#45;icon" />-->
          <lazy-nuxt-img
            v-if="shipping?.images?.length"
            class="shipping-item--icon"
            :src="shipping?.images[0]?.path"
          />
          <span>{{ shipping?.name }}</span>
          <span v-if="shipping?.label">-</span>
          <!--          <ProductAvailability v-if="shipping?.code === 'standard'" :variant="variant" />-->
          <span class="shipping-item--description">{{ shipping?.label }}</span>
        </li>
      </ul>
    </DisclosurePanel>
  </Disclosure>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'

const variant = inject('currentVariant')
const product = inject('product')
</script>

<style lang="postcss" scoped>
.shipping-item {
  @apply flex text-xs items-center gap-2.5;
}

.shipping-item--icon {
  @apply h-4 text-green-ibis;
}
</style>
