<template>
  <div>
    <ListHorizontal
      :title="$t('Модельний ряд')"
      :slides="variants"
      :rows="1"
      :slides-per-view="3"
      :breakpoints="breakpoints"
      title-class="text-md font-medium text-gray-mud"
      slide-class="h-[60px]"
      :active-slide="activeSlide"
    >
      <template #default="{ slide }">
        <ProductOptionsOptionSlide
          class="first:border-y border-l last:border-r mb-7 bg-white"
          :variant="slide"
          :selected="currentVariant?.slug === slide?.slug"
        />
      </template>
    </ListHorizontal>
  </div>
</template>

<script setup>
import get from 'lodash/get'

const product = inject('product')
const currentVariant = inject('currentVariant')

const variants = computed(() =>
  product.value?.variants
    ?.map(variant => {
      return {
        image: get(variant, ['images', 0, 'path']),
        inStock: variant?.inStock,
        slug: variant?.slug
      }
    })
    ?.sort((a, b) => {
      return b.inStock - a.inStock
    })
)

const activeSlide = computed(() => {
  return variants.value?.findIndex(v => v?.slug === currentVariant.value?.slug)
})

const breakpoints = {
  600: {
    slidesPerView: 3
  },
  900: {
    slidesPerView: 4
  },
  1050: {
    slidesPerView: 5
  },
  1200: {
    slidesPerView: 5
  },
  1350: {
    slidesPerView: 6
  },
  1500: {
    slidesPerView: 7
  }
}
</script>
